<template>
  <div class="planning">
    <section class="planning-grid w-95p mt-2">
      <div v-if="errored" class="empty">
        <p>
          We are not able to retrieve this information at the moment, please try
          again later
        </p>
      </div>

            <div
        style="width: 100%; text-align: center"
        class="spin-holder mt-4"
        v-if="loading"
      >
         <i class="spinner fa fa-spinner fa-spin"></i>
        <p>Loading Planning</p>
      </div>

      <table class="table-striped w-full" v-else>
        <thead class="text-gray-400">
          <tr>
            <th width="10%" class="pb-12"></th>
            <th
              :id="term.key"
              v-for="term in terms"
              :key="term.key"
              class="text-center"
              width="15%"
            >
              <h4 class="text-sm">{{ term.name }}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in plannerArrayFiltered" :key="index">
            <td
              class="text-gray-400 text-sm font-bold align-top pt-8 pl-4 cell-border"
            >
              {{ row.year }}
            </td>
            <td
              :id="row.year"
              v-for="(term, term_index) in row.terms"
              :key="term_index"
              class="border-b-8 border-l-2 border-gray-100 text-gray-600 text-center m-0 p-0"
            >
              <table class="w-full border-0 m-0 p-0">
                <tr
                  v-for="unit_index in 2"
                  :key="unit_index"
                  v-bind:style="getBackgroundColor(term.colour, unit_index)"
                >
                  <td
                    v-if="term.units[unit_index - 1]"
                    class="group cursor-pointer text-xs font-semibold hover:bg-gray-200 hover:text-gray-700 hover:text-xs h-16"
                    @click="unitSelect(term.units[unit_index - 1])"
                  >
                    <span>
                      {{ term.units[unit_index - 1].name }}
                    </span>
                  </td>

                  <td
                    v-else
                    class="h-16 cursor-pointer text-xs text-center hover:bg-green-100 hover:bg-opacity-25 hover:text-white"
                    @click="selectCell(term)"
                  >
                    <i class="fas fa-plus-circle text-xl opacity-25 group-hover:block"></i>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <planningAdd
      @close="showPlanningEdit = false"
      @refresh="refreshGrid"
      v-if="editValid"
      v-show="showPlanningEdit"
      :editMode="editMode"
      :term="selectedTerm"
      :year="selectedYear"
    ></planningAdd>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  termList,
  colours,
} from "@/assets/js/utils";

const planningAdd = () => import("@/components/planning/ThePlanningAdd.vue");
import { planningMixin} from "@/mixins/planningMixin.js";
import { yearGroupMixin} from "@/mixins/yearGroupMixin.js";

export default {
  mixins: [planningMixin, yearGroupMixin],
  name: "planning",
  props: [],
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      currentAccess: "currentAccess",
    }),
    editValid() {
      return this.selectedTerm != undefined && this.selectedYear > 0;
    },
    gridRows() {
      return this.settings.display.planningRows == undefined
        ? 2
        : this.settings.display.planningRows;
    },
  },
  data() {
    return {
      mixinName: "planning",
      hasAccess: false,
      errored: false,
      loading: true,
      editMode: false,
      terms: termList(),
      colours: colours(),
      showPlanningEdit: false,
      selectedTerm: undefined,
      selectedYear: 0,
    };
  },
  created() {
    this.loading = true;
    this.hasAccess = this.currentUser.isSubscriber;
  },
  mounted() {
  },
  methods: {
    dataLoaded() {
      this.loading = false;
    },
    selectCell(term) {
      this.showPlanningEdit = true;
      this.selectedYear = term.year_id;
      this.selectedTerm = this.findByKey(this.terms, term.term_key);
    },
    unitSelect(unit) {
      this.$store.commit("setUnit", unit);
      this.$router.push({ name: "UnitView" });
      window.scrollTo(0, 0);
    },
    refreshGrid(data) {
      let payload = {
        term: this.selectedTerm.key,
        year: this.selectedYear,
        unit: data,
      };
      this.$store.commit("ADD_PLANNING_UNIT", payload);
      this.plannerArrayFiltered = this.groupPlannerArray();
      this.showPlanningEdit = false;
    },

    groupPlannerArray() {
         const result = this.plannerArray.reduce((acc, d) => {
        if (!acc[d.year]) {
          acc[d.year] = [{ year: d.year, terms: [d] }];
        } else {
          var terms = acc[d.year].flatMap((r) => r.terms);
          terms.push(d);
          acc[d.year] = [{ year: d.year, terms: terms }];
        }
        return acc;
      }, {});
      return [].concat.apply([], Object.values(result));
    },
    getBackgroundColor(term, index) {
      let ref = index > 1 ? 0.7 : 0.4;
      return {
        backgroundColor: "rgba(" + term.colour + "," + ref + ")",
      };
    },
    editGrid() {
      this.$router.push({
        name: "PlanningEdit"
      });
      window.scrollTo(0, 0);
    },
  },
  components: {
    planningAdd,
  },
};
</script>

<style scoped>
  .cell-border {
    border-bottom-color: #fafafa;
    border-bottom-width: 11px;
    border-top-width: 2px;
    border-top-color: #fafafa;
  }
</style>
